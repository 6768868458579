@import '~assets/styles/variables';

.item {
  border-bottom: $border--small;
  background-color: $color--background;
  &__main {
    display: flex;
    justify-content: space-between;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
  &__notes {
    max-height: 0;
    overflow: hidden;
    @include transition-in(max-height);
    background-color: $color--background;
    border-top: $border--small;
    &--open {
      max-height: 300px;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      column-gap: 4rem;
      border-bottom: $border--small;
      &__source {
        flex-basis: 100px;
        text-align: right;
      }
    }
  }
}

.scroll-list__item--date-status {
  display: flex;
  justify-content: space-between;
}

.form-viewer__packet-content__header__title-date-status-block {
  grid-column: 1 / span 2;
}

.form-viewer__packet-content__header__date-status-line {
  display: flex;
  justify-content: space-between;
}
